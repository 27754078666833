#google_translate_element {
    position: fixed;
    bottom: 15px;
    left: 15px;
}

.goog-logo-link {
    display:none !important;
}

.goog-te-gadget {
    color: transparent !important;
}

.goog-te-gadget span {
    display: none !important;
}

.goog-te-gadget .goog-te-combo {
    color: #666 !important;
    outline: none !important;
    height: 25px;
    width: 140px;
    border-radius: 6px;
    padding: 0 8px;
}
